/*import { openDialogAsIframe_history2 } from './dialoghelper';*/

Office.onReady(() => {
  // If needed, Office.js is ready to be called
});

var clickEvent;
var dialog;

function dialogCallback(asyncResult) {
  if (asyncResult.status == "failed") {

    // In addition to general system errors, there are 3 specific errors for 
    // displayDialogAsync that you can handle individually.
    switch (asyncResult.error.code) {
      case 12004:
        showNotification("Domain is not trusted");
        break;
      case 12005:
        showNotification("HTTPS is required");
        break;
      case 12007:
        showNotification("A dialog is already opened.");
        break;
      default:
        showNotification(asyncResult.error.message);
        break;
    }
  }
  else {
    dialog = asyncResult.value;
    /*Messages are sent by developers programatically from the dialog using office.context.ui.messageParent(...)*/
    dialog.addEventHandler(Office.EventType.DialogMessageReceived, messageHandler);

    /*Events are sent by the platform in response to user actions or errors. For example, the dialog is closed via the 'x' button*/
    dialog.addEventHandler(Office.EventType.DialogEventReceived, eventHandler);
  }
}


function messageHandler(arg) {
  console.log("Helllooo message handelr");
  if (arg.message == "") {
    window.location.replace("/newPage.html");
  }
  else
    dialog.close();
  showNotification(arg.message);
}

function eventHandler(arg) {
  // In addition to general system errors, there are 2 specific errors 
  // and one event that you can handle individually.
  switch (arg.error) {
    case 12002:
      showNotification("Cannot load URL, no such page or bad URL syntax.");
      break;
    case 12003:
      showNotification("HTTPS is required.");
      break;
    case 12006:
      // The dialog was closed, typically because the user the pressed X button.
      showNotification("Dialog closed by user");
      break;
    default:
      showNotification("Undefined error in dialog window");
      break;
  }
}

function showNotification(text) {
  console.log("Helllooo show notification");
  //writeToDoc(text);
  console.log(text);

  //Required, call event.completed to let the platform know you are done processing.
  clickEvent.completed();
}

function writeToDoc(text) {
  console.log("Helllooo write document");
  Office.context.document.setSelectedDataAsync(text,
    function (asyncResult) {
      //var error = asyncResult.error;
      if (asyncResult.status === "failed") {
        console.log("Unable to write to the document: " + asyncResult.error.message);
      }
    });
}

/**
 * Shows a notification when the add-in command is executed.
 * @param event
 */
function action(event: Office.AddinCommands.Event) {
  /*const message: Office.NotificationMessageDetails = {
    type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
    message: "Performed action.",
    icon: "Icon.80x80",
    persistent: true
  };

  // Show a notification message
  Office.context.mailbox.item.notificationMessages.replaceAsync("action", message);*/

  // Be sure to indicate when the add-in command function is complete

  clickEvent = event;
  //openDialogAsIframe_history();
  Office.context.ui.displayDialogAsync(window.location.origin + "/comminfo.html",
    { height: 50, width: 50, displayInIframe: true }, dialogCallback);
  event.completed();
}


function getHistory(event: Office.AddinCommands.Event) {
  clickEvent = event;
  //openDialogAsIframe_history();
  Office.context.ui.displayDialogAsync(window.location.origin + "/commhist.html",
    { height: 50, width: 50, displayInIframe: true }, dialogCallback);
  event.completed();
}

/*function openDialogAsIframe_history() {
  console.log("Helllooo openhistorzas Iframe");

  Office.context.ui.displayDialogAsync(window.location.origin + "/index.html",
    { height: 50, width: 50, displayInIframe: true }, dialogCallback);
}*/

function showAccess(event: Office.AddinCommands.Event) {
  clickEvent = event;
  //openDialogAsIframe_history();
  Office.context.ui.displayDialogAsync(window.location.origin + "/commaccess.html",
    { height: 50, width: 50, displayInIframe: true }, dialogCallback);
  event.completed();
}


function getGlobal() {
  return typeof self !== "undefined"
    ? self
    : typeof window !== "undefined"
      ? window
      : typeof global !== "undefined"
        ? global
        : undefined;
}

const g = getGlobal() as any;

// the add-in command functions need to be available in global scope
g.action = action;

g.getHistory = getHistory;
g.showAccess = showAccess;
//g.showAdvice = showAdvice;
//g.getTaskHistory = getTaskHistory;
